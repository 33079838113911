"use client";

import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/utils";
import { motion } from "framer-motion";
import { CheckCircleIcon } from "lucide-react";
import { Fragment, useState } from "react";
import PaddleCheckoutButton from "@/components/PaddleCheckoutButton/PaddleCheckoutButton";
// import PricingBadge from "@/components/PricingBadge/PricingBadge";

type Tab = "1year" | "2year" | "lifetime";

// Define a type for product details
type ProductDetails = {
    key: string;
    // productId: number;
    // variationId: number;
    paddleProductId: string;
    couponCode: string;
};

const oneYearsPricesURL: Record<string, ProductDetails> = {
    "200_site": {
        key: "200_site",
        // productId: 30532,
        // variationId: 30537,
        paddleProductId: "pri_01jcgd17pxt74cph8bypb3qz2w",
        couponCode: "HOLIDAY50",
    },
    "500_site": {
        key: "500_site",
        // productId: 30532,
        // variationId: 30597,
        paddleProductId: "pri_01jcgssdt4d5cvy5jvwyghz7y0",
        couponCode: "HOLIDAY50",
    },
    "1000_site": {
        key: "1000_site",
        // productId: 30532,
        // variationId: 30604,
        paddleProductId: "pri_01jcgtdb9bjjkkzaaj3npc6b89",
        couponCode: "HOLIDAY50",
    },
};

const twoYearsPricesURL: Record<string, ProductDetails> = {
    "200_site": {
        key: "200_site",
        // productId: 30532,
        // variationId: 30595,
        paddleProductId: "pri_01jcgsf46q2702wgpab6hpd2rz",
        couponCode: "HOLIDAY50",
    },
    "500_site": {
        key: "500_site",
        // productId: 30532,
        // variationId: 30599,
        paddleProductId: "pri_01jcgstn5f1jzbkqhmyh9aqtf3",
        couponCode: "HOLIDAY50",
    },
    "1000_site": {
        key: "1000_site",
        // productId: 30532,
        // variationId: 30600,
        paddleProductId: "pri_01jcgstwsztjjqqa70brn9c8g5",
        couponCode: "HOLIDAY50",
    },
};

const lifetimeYearsPricesURL: Record<string, ProductDetails> = {
    "200_site": {
        key: "200_site",
        // productId: 30532,
        // variationId: 30596,
        paddleProductId: "pri_01jcgsf9jcfe9b6v2y937epddn",
        couponCode: "HOLIDAY50",
    },
    "500_site": {
        key: "500_site",
        // productId: 30532,
        // variationId: 30598,
        paddleProductId: "pri_01jcgstehfzna3d925bb7y8ky8",
        couponCode: "HOLIDAY50",
    },
    "1000_site": {
        key: "1000_site",
        // productId: 30532,
        // variationId: 30602,
        paddleProductId: "pri_01jcgsx6ywc0a7q1tbdxn7fc87",
        couponCode: "HOLIDAY50",
    },
};

const PricingCards = () => {
    const MotionTabTrigger = motion.create(TabsTrigger);

    const [activeTab, setActiveTab] = useState<Tab>("1year");
    // const [oneYearPrice, setOneYearPrice] = useState("200_site");
    // const [twoYearPrice, setTwoYearPrice] = useState("200_site");
    // const [lifetimePrice, setLifetimePrice] = useState("200_site");
    const [numOfLicense, setNumOfLicense] = useState("200_site");

    return (
        <Tabs
            defaultValue="1year"
            className="jlt-flex jlt-flex-col jlt-items-center jlt-justify-center">
            <TabsList className="jlt-bg-purple-500/[0.15] jlt-border jlt-border-border">
                <MotionTabTrigger
                    value="1year"
                    onClick={() => setActiveTab("1year")}
                    className="jlt-relative">
                    {activeTab === "1year" && (
                        <motion.div
                            layoutId="active-tab-indicator"
                            transition={{
                                type: "spring",
                                bounce: 0.5,
                            }}
                            className="jlt-absolute jlt-top-0 jlt-left-0 jlt-w-full jlt-h-full jlt-bg-background shadow-sm jlt-rounded-md jlt-z-10 jlt-border jlt-border-[#a855f7]"
                        />
                    )}
                    <span className="jlt-z-20">1 Year Support</span>
                </MotionTabTrigger>
                <MotionTabTrigger
                    value="2year"
                    onClick={() => setActiveTab("2year")}
                    className="jlt-relative">
                    {activeTab === "2year" && (
                        <motion.div
                            layoutId="active-tab-indicator"
                            transition={{
                                type: "spring",
                                bounce: 0.5,
                            }}
                            className="jlt-absolute jlt-top-0 jlt-left-0 jlt-w-full jlt-h-full jlt-bg-background shadow-sm jlt-rounded-md jlt-z-10 jlt-border jlt-border-[#a855f7]"
                        />
                    )}
                    <span className="jlt-z-20">2 Year Support</span>
                </MotionTabTrigger>
                <MotionTabTrigger
                    value="lifetime"
                    onClick={() => setActiveTab("lifetime")}
                    className="jlt-relative">
                    {activeTab === "lifetime" && (
                        <motion.div
                            layoutId="active-tab-indicator"
                            transition={{
                                type: "spring",
                                bounce: 0.5,
                            }}
                            className="jlt-absolute jlt-top-0 jlt-left-0 jlt-w-full jlt-h-full jlt-bg-background shadow-sm jlt-rounded-md jlt-z-10 jlt-border jlt-border-[#a855f7]"
                        />
                    )}
                    <span className="jlt-z-20">Lifetime Support</span>
                </MotionTabTrigger>
            </TabsList>
            <div className="jlt-grid jlt-grid-cols-1 lg:jlt-grid-cols-3 jlt-gap-5 jlt-w-full md:jlt-gap-8 jlt-flex-wrap jlt-max-w-5xl jlt-mx-auto jlt-pt-6">
                {/* 1 year plan start */}
                <TabsContent value="1year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Starter</div>
                                    {/* <PricingBadge text="6 Month Free" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For Freelancers, Solopreneurs</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {/* <del className="jlt-text-2xl jlt-text-muted-foreground">$69</del>{" "}
                                $39 */}
                                $69
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Just $13.8 per site annually
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    5 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend Access",
                                    tooltip: "Access WP Spotlight from only the backend.",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            {/* <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Local - Buy Now",
                                    // productId: 30419,
                                    // productVariation: 30421,
                                    paddleProductId: "pri_01jcd6cwg2djqxrbegv86xh9jq",
                                    // couponCode: "kb12",
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [98, 97],
                                }}
                            /> */}

                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: 30532,
                                    // productVariation: 30539,
                                    paddleProductId: "pri_01jcgd16p6z3p3qkmwzdae4m99",
                                    // couponCode: "HOLIDAY43",
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="1year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl",
                            "Business" === "Business" && "jlt-border-2 jlt-border-purple-500"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Business</div>
                                    {/* <PricingBadge text="6 Month Free" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For small businesses and agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {/* <del className="jlt-text-2xl jlt-text-muted-foreground">$129</del>{" "}
                                $64 */}
                                $129
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Just $2.58 per site annually
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    50 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: 30532,
                                    // productVariation: 30536,
                                    paddleProductId: "pri_01jcgd17f7krm7r1adfm33hy1g",
                                    // couponCode: "HOLIDAY50",
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="1year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Agency</div>
                                    {/* <PricingBadge text="50% OFF" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For large organizations and Agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {[
                                    {
                                        key: "200_site",
                                        // price: 149,
                                        mainPrice: 299,
                                    },
                                    {
                                        key: "500_site",
                                        // price: 398,
                                        mainPrice: 398,
                                    },
                                    {
                                        key: "1000_site",
                                        // price: 399,
                                        mainPrice: 798,
                                    },
                                ].map((plan) =>
                                    plan.key === numOfLicense ? (
                                        <Fragment key={plan.key}>
                                            {/* <del className="jlt-text-2xl jlt-text-muted-foreground">
                                                ${plan.mainPrice}
                                            </del>{" "} */}
                                            {/* ${plan.price} */}${plan.mainPrice}
                                        </Fragment>
                                    ) : null
                                )}
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Streamline Multiple Websites
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-items-center jlt-gap-3 jlt-justify-between -jlt-mt-[9px]">
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "200_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("200_site")}>
                                    200
                                </button>
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "500_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("500_site")}>
                                    500
                                </button>
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "1000_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("1000_site")}>
                                    1000
                                </button>
                            </div>

                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: oneYearsPricesURL?.[numOfLicense]?.productId,
                                    // productVariation:
                                    // oneYearsPricesURL?.[numOfLicense]?.variationId,
                                    paddleProductId:
                                        oneYearsPricesURL?.[numOfLicense]?.paddleProductId,
                                    // couponCode: oneYearsPricesURL?.[numOfLicense]?.couponCode,
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                {/* 1 year plan end */}

                {/* 2 year plan start */}
                <TabsContent value="2year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Starter</div>
                                    {/* <PricingBadge text="6 Month Free" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For Freelancers, Solopreneurs</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {/* <del className="jlt-text-2xl jlt-text-muted-foreground">$99</del>{" "}
                                $69 */}
                                $99
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /2year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Save $39 compared to two 1 year plans
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    5 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend Access",
                                    tooltip: "Access WP Spotlight from only the backend.",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: 30532,
                                    // productVariation: 30535,
                                    paddleProductId: "pri_01jcgd16xx9334neaxdtck067c",
                                    // couponCode: "HOLIDAY30",
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="2year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl",
                            "Business" === "Business" && "jlt-border-2 jlt-border-purple-500"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Business</div>
                                    {/* <PricingBadge text="6 Month Free" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For small businesses and agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {/* <del className="jlt-text-2xl jlt-text-muted-foreground">$199</del>{" "}
                                $119 */}
                                $199
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /2year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Save $59 compared to two 1 year plans
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />{" "}
                                    50 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: 30532,
                                    // productVariation: 30593,
                                    paddleProductId: "pri_01jcgsekn99bazvmhzz1wgj08p",
                                    // couponCode: "HOLIDAY40",
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="2year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Agency</div>
                                    {/* <PricingBadge text="50% OFF" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For large organizations and Agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {[
                                    {
                                        key: "200_site",
                                        // price: 199,
                                        mainPrice: 399,
                                    },
                                    {
                                        key: "500_site",
                                        // price: 299,
                                        mainPrice: 598,
                                    },
                                    {
                                        key: "1000_site",
                                        // price: 549,
                                        mainPrice: 1098,
                                    },
                                ].map((plan) =>
                                    plan.key === numOfLicense ? (
                                        <Fragment key={plan.key}>
                                            {/* <del className="jlt-text-2xl jlt-text-muted-foreground">
                                                ${plan.mainPrice}
                                            </del>{" "} */}
                                            {/* ${plan.price} */}${plan.mainPrice}
                                        </Fragment>
                                    ) : null
                                )}
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /2year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Future-Proof Your Agency
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-items-center jlt-gap-3 jlt-justify-between -jlt-mt-[9px]">
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "200_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("200_site")}>
                                    200
                                </button>
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "500_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("500_site")}>
                                    500
                                </button>
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "1000_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("1000_site")}>
                                    1000
                                </button>
                            </div>

                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: twoYearsPricesURL?.[numOfLicense]?.productId,
                                    // productVariation:
                                    // twoYearsPricesURL?.[numOfLicense]?.variationId,
                                    paddleProductId:
                                        twoYearsPricesURL?.[numOfLicense]?.paddleProductId,
                                    // couponCode: twoYearsPricesURL?.[numOfLicense]?.couponCode,
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                {/* 2 year plan end */}

                {/* lifetime plan start */}
                <TabsContent value="lifetime">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Starter</div>
                                    {/* <PricingBadge text="6 Month Free" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For Freelancers, Solopreneurs</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {/* <del className="jlt-text-2xl jlt-text-muted-foreground">$199</del>{" "}
                                $129 */}
                                $199
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /Lifetime
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Lifetime access for one time payment
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    5 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend Access",
                                    tooltip: "Access WP Spotlight from only the backend.",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: 30532,
                                    // productVariation: 30540,
                                    paddleProductId: "pri_01jcgd176fajx49yb33x2czkw4",
                                    // couponCode: "HOLIDAY35",
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="lifetime">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl",
                            "Business" === "Business" && "jlt-border-2 jlt-border-purple-500"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Business</div>
                                    {/* <PricingBadge text="6 Month Free" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For small businesses and agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {/* <del className="jlt-text-2xl jlt-text-muted-foreground">$299</del>{" "}
                                $199 */}
                                $299
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /Lifetime
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Lifetime access for one time payment
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    50 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: 30532,
                                    // productVariation: 30594,
                                    paddleProductId: "pri_01jcgsewtrjj9w9rd9kk1585ez",
                                    // couponCode: "HOLIDAY33",
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="lifetime">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                <div className="jlt-flex jlt-items-center jlt-justify-between">
                                    <div>Agency</div>
                                    {/* <PricingBadge text="50% OFF" /> */}
                                </div>
                            </CardTitle>
                            <CardDescription>For large organizations and Agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {[
                                    {
                                        key: "200_site",
                                        // price: 399,
                                        mainPrice: 799,
                                    },
                                    {
                                        key: "500_site",
                                        // price: 599,
                                        mainPrice: 1198,
                                    },
                                    {
                                        key: "1000_site",
                                        // price: 799,
                                        mainPrice: 1598,
                                    },
                                ].map((plan) =>
                                    plan.key === numOfLicense ? (
                                        <Fragment key={plan.key}>
                                            {/* <del className="jlt-text-2xl jlt-text-muted-foreground">
                                                ${plan.mainPrice}
                                            </del>{" "} */}
                                            {/* ${plan.price} */}${plan.mainPrice}
                                        </Fragment>
                                    ) : null
                                )}
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /Lifetime
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Lifetime access for one time payment
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-items-center jlt-gap-3 jlt-justify-between -jlt-mt-[9px]">
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "200_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("200_site")}>
                                    200
                                </button>
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "500_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("500_site")}>
                                    500
                                </button>
                                <button
                                    className={cn(
                                        "jlt-w-full jlt-border jlt-rounded-sm jlt-bg-purple-500/[0.07] jlt-py-1",
                                        numOfLicense === "1000_site"
                                            ? "jlt-border-[#a855f7]"
                                            : "jlt-border-border"
                                    )}
                                    onClick={() => setNumOfLicense("1000_site")}>
                                    1000
                                </button>
                            </div>

                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Find users instantly from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Edit content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    // productId: lifetimeYearsPricesURL?.[numOfLicense]?.productId,
                                    // productVariation:
                                    // lifetimeYearsPricesURL?.[numOfLicense]?.variationId,
                                    paddleProductId:
                                        lifetimeYearsPricesURL?.[numOfLicense]?.paddleProductId,
                                    // couponCode: lifetimeYearsPricesURL?.[numOfLicense]?.couponCode,
                                    settings: {
                                        // allowDiscountRemoval: false,
                                    },
                                    lists: [3],
                                    tags: [99, 97],
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                {/* lifetime plan end */}
            </div>
        </Tabs>
    );
};

export default PricingCards;
